.brand-ibf {
  background-image: url(../img/ibf-bg.jpg);
}

.brand-rc {
  background-image: url(../img/rc-bg.jpg);
}

.brand-kcf {
  background-image: url(../img/kcf-bg.jpg);
}

.brand-none {
  background-image: url(../img/none-bg.jpg);
}

.brand-ibf .logo-link,
.brand-ibf .logo-img {
  width: 220px;
  height: 55px;
}

.brand-kcf .logo-link,
.brand-kcf .logo-img {
  width: 240px;
  height: auto;
}

.brand-rc .logo-link,
.brand-rc .logo-img {
  width: 200px;
  height: auto;
}
