html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.43;
  color: #fff;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-large {
  font-size: 22px;
}

.text-huge {
  font-size: 34px;
}

.mt-0 {
  margin-top: 0 !important;
}

.pr {
  padding-right: 15px !important;
}
