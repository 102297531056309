.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #20ad77;
  border-color: #fff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #188259;
  border-color: #fff;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #188259;
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: rgba(24, 130, 89, 0.8);
  border-color: #fff;
  cursor: not-allowed;
}

.btn-lg {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-submit-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-submit {
  min-width: 200px;
  font-size: 18px;
  text-transform: uppercase;
}

.submit-validation-notice {
  margin-top: 5px;
  font-size: 14px;
}

.files-upload-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.file-wrapper {
  display: flex;
  margin-bottom: 25px;
}

.file-wrapper__left {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex: 0 0 120px;
}

.file-wrapper__right {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
}

.file-wrapper__dropzone {
  width: 120px;
  height: 40px;
  background-image: url('./img/upload-icon.png');
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px dashed #fff;
  border-radius: 10px;
  cursor: pointer;
}

.file-wrapper__dropzone--selected {
  background-color: #158ae0;
}

.file-wrapper__label {
  margin-bottom: 2px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1;
}

.file-wrapper__filename {
  margin-top: 3px;
  font-family: monospace;
  font-size: 14px;
}

@media (max-width: 575.98px) {
  .file-wrapper {
    align-items: center;
    flex-direction: column;
  }

  .file-wrapper__left,
  .file-wrapper__right {
    width: 100%;
  }

  .file-wrapper__left {
    flex: 1;
    order: 2;
    justify-content: center;
  }

  .file-wrapper__right {
    flex: 1;
    order: 1;
    padding: 0;
    margin-bottom: 7.5px;
  }

  .file-wrapper__label,
  .file-wrapper__description,
  .file-wrapper__filename {
    text-align: center;
  }
}

.working-files-table {
  max-width: 900px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
}

.working-files-table__row {
  display: flex;
  min-height: 36px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.working-files-table__col--name {
  flex: 1;
}

.working-files-table__col--status {
  flex: 0 0 80px;
  text-align: center;
}

.working-files-table__col--uploaded {
  color: #20ad77;
}

.link-example-doc {
  color: #fff;
  background-image: url(./img/download-icon.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

/* LOADING */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 12px;
}
.lds-ellipsis div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* END LOADING */
